<script>
import { useToast } from "vue-toastification";
import { Modal } from "usemodal-vue3";
import { HalfCircleSpinner } from "epic-spinners";
import Vue3TagsInput from 'vue3-tags-input';
export default {
    data: () => ({
        loading: false,
        languages: [
            "Français",
            "Anglais",
            "Hébreu"
        ],
        language: null,
        themes: [
            "Annonce d'événements",
            "Collecte de dons",
            "Sensibilisation",
            "Newsletter / Communication régulière",
            "Adhésion et bénévolat",
            "Communication interne",
            "Soutien et solidarité",
            "Remerciements et reconnaissance",
            "Éducation et information",
            "Engagement social et participation citoyenne",
            "Développement personnel et bien - être"
        ],
        theme: null,
        styles: [
            "Formel et professionnel",
            "Amical et chaleureux",
            "Dynamique et engageant",
            "Inspirant et motivant",
            "Neutre et informatif"
        ],
        style: null,
        types: ["Email",
            "Post sur les réseaux sociaux",
            "Article de blog",
            "Communiqué de presse",
            "Affiche ou flyer",
            "Lettre formelle",
            "Message WhatsApp",
            "SMS"],
        type: null,
        lengths: ["100 mots",
            "100 caractères",
            "300 mots",
            "300 mots",
            "20 mots",
            "200 mots",
            "50 mots",
            "160 caractères"],
        keywords: [],
        conf: true,
        request: null,
        output: null
    }),
    setup() {
        const toast = useToast();
        return {
            toast,
            keywordsPopupVisible: ref(false),
        };
    },
    methods: {
        keywordsChanged(keywords) {
            this.keywords = keywords
        },
        buildRequest() {
            var string = ""
            this.keywords.forEach((key) => {
                string = string + key + ", "
            });

            this.request = "Rédigez un message en " + this.language + " avec les éléments suivants: \n"
            this.request += "Thème: " + this.theme + "\n"
            this.request += "Style d'écriture: " + this.style + "\n"
            this.request += "Type de communication: " + this.type + "\n"
            this.request += "Longueur maximale: " + this.lengths[this.types.indexOf(this.type)] + "\n"
            if (this.keywords.length != 0) {
                this.request += "Mots clés: " + string + "\n"
            }
        },
        generate() {
            this.conf = false
            this.buildRequest()
            this.loading = true
            $fetch('https://api.openai.com/v1/chat/completions', {
                headers: {
                    ContentType: "application/json",
                    Authorization: "Bearer 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"
                },
                method: 'POST',
                body: {
                    "model": "gpt-4o-mini",
                    "messages": [
                        {
                            "role": "user",
                            "content": [
                                {
                                    "type": "text",
                                    "text": this.request
                                }
                            ]
                        }
                    ]
                }
            }).then((response) => {
                this.loading = false
                this.output = response.choices[0].message.content
            })
        },
        onCopy() {
            this.toast.success('Copié !')
        }
    },
    components: {
        HalfCircleSpinner, Modal, Vue3TagsInput
    }
}
</script>
<template>
    <div v-if="conf == true">
        <div class="mb-3">
            <span class="fw-600 text-black m-2">Sélectionnez la langue votre texte</span>
            <span class="btn btn-sm m-1" v-for="(l, index) in languages"
                :class="language == l ? 'btn-primary' : 'btn-outline-primary'" @click="language = l">
                {{ l }}
            </span>
        </div>
        <div class="mb-3" v-if="language != null">
            <div class="fw-600 text-black m-2">Sélectionnez un thème pour votre texte</div>
            <span class="btn btn-sm m-1" v-for="(t, index) in themes"
                :class="theme == t ? 'btn-primary' : 'btn-outline-primary'" @click="theme = t">
                {{ t }}
            </span>
            <span class="btn btn-sm m-1"
                :class="!themes.includes(theme) && theme != null ? 'btn-primary' : 'btn-outline-primary'"
                @click="theme = ''">
                Autre</span>
            <input type="text" class="form-control mt-2" v-model="theme"
                v-if="!themes.includes(theme) && theme != null" />
        </div>
        <div class="mb-3" v-if="language != null && theme != null">
            <div class="fw-600 text-black m-2">Sélectionnez le style d'écriture</div>
            <span class="btn btn-sm m-1" v-for="(s, index) in styles"
                :class="style == s ? 'btn-primary' : 'btn-outline-primary'" @click="style = s">
                {{ s }}
            </span>
            <span class="btn btn-sm m-1"
                :class="!styles.includes(style) && style != null ? 'btn-primary' : 'btn-outline-primary'"
                @click="style = ''">
                Autre</span>
            <input type="text" class="form-control mt-2" v-model="style"
                v-if="!styles.includes(style) && style != null" />
        </div>
        <div class="mb-3" v-if="language != null && theme != null && style != null">
            <div class="fw-600 text-black m-2">Sélectionnez le type de communication</div>
            <span class="btn btn-sm m-1" v-for="(t, index) in types"
                :class="type == t ? 'btn-primary' : 'btn-outline-primary'" @click="type = t">
                {{ t }}
            </span>
        </div>
        <div class="mb-3" v-if="language != null && theme != null && style != null && type != null">
            <div class="fw-600 text-black m-2">
                Saisissez quelques <b>mots-clés</b> séparés par des virgules, qui décrivent le texte
                souhaité
            </div>
            <vue3-tags-input :tags="keywords" @on-tags-changed="keywordsChanged" />
        </div>
        <div class="d-flex justify-content-end"
            v-if="language != null && theme != null && style != null && type != null">
            <button class="btn btn-sm btn-success mx-2" type="button" @click="generate">
                <i class="fa-solid fa-wand-magic-sparkles"></i> Générer
            </button>
        </div>
    </div>
    <div v-else>
        <div class="mb-3">
            <div class="d-flex justify-content-center" v-if="loading">
                <HalfCircleSpinner :animation-duration="1000" :size="20" color="#fa764b" />
            </div>
            <div v-else>
                <div class="fw-600 text-black mb-4">
                    Votre texte :
                </div>
                <div class="fw-600 text-black mb-4" style="white-space: pre-wrap">
                    {{ output }}
                </div>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-sm btn-success mx-2" type="button" v-tooltip="'Cliquer pour copier'"
                        v-clipboard:copy="output" v-clipboard:success="onCopy">
                        <i class="fa-solid fa-copy"></i> Copier
                    </button>
                    <button class="btn btn-sm btn-black mx-2" type="button" @click="conf = true">
                        <i class="fa-solid fa-wrench"></i> Reparamétrer mon texte
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>